<template>
  <section>
    <CampusHeader
        :can-create="!!$store.getters['entities/user-permissions/find']('create applications')"
        :activeTab="0"
        @start-creating="startCreatingApplication"
        :manage-campuses="true"
        :can-all="true"
        :header-text="'Applications'"
        :create-text="'New Application'"
        :header_tabs="[{ title: `Applications ${isTable?'Table':'Board'}` }]"
    ></CampusHeader>
    <section class="section pt-4 box ">
      <div class="">
        <title-bar>{{ board_title }}</title-bar>
        <div class="columns">
          <div class="column is-10">
            <b-field grouped group-multiline>
              <b-field>
                <b-radio-button v-model="filter" native-value="learner">
                  <b-icon :icon="$tc('icons.learners')"></b-icon>
                  <span>{{ $tc('Learner', 1) }}</span>
                </b-radio-button>
                <b-radio-button v-model="filter" native-value="guardian">
                  <b-icon :icon="$tc('icons.guardians')"></b-icon>
                  <span>Guardian</span>
                </b-radio-button>
                <b-radio-button v-model="filter" native-value="owner">
                  <b-icon :icon="$tc('icons.users')"></b-icon>
                  <span>Owner</span>
                </b-radio-button>
              </b-field>
              <b-field>
                <b-button @click="setNoOwner">No Owner</b-button>
                <!--            <b-button @click="setNoCourse">No Course</b-button>-->
              </b-field>
              <!--          <b-radio-button v-model="rplCatFilter" :native-value="1">-->
              <!--            <span>RPL/CAT requested</span>-->
              <!--          </b-radio-button>-->
              <b-field>
                <b-select v-model="yearFilter" placeholder="Year">
                  <option
                      v-for="year of $store.getters['dates/years']"
                      :key="year + 'year'"
                      :value="year"
                  >{{ year }}
                  </option>
                </b-select>

              </b-field>
              <b-field>
                <b-radio-button v-if="for_programmes!==1" :disabled="yearFilter == null" v-model="filter"
                                native-value="phase">
                  <b-icon :icon="$tc('icons.phases')"></b-icon>
                  <span>Phase</span>
                </b-radio-button>
                <b-radio-button v-if="for_programmes!==1" :disabled="yearFilter == null" v-model="filter"
                                native-value="stage">
                  <b-icon :icon="$tc('icons.stages')"></b-icon>
                  <span>Stage</span>
                </b-radio-button>
                <b-radio-button v-if="for_programmes===1"  v-model="filter"
                                native-value="programme">
                  <span>Programme</span>
                </b-radio-button>

              </b-field>
              <b-select placeholder="Application Status" v-model="statusFilter">
                <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
              </b-select>
              <b-field>
                <b-button @click="setShowProgramme">Show Programme Applications</b-button>
              </b-field>
              <b-field>
                <b-button @click="setWonLost">Show won/lost</b-button>
              </b-field>
              <b-field>
                <b-button @click="togglePriority()">Order by priority
                </b-button>
              </b-field>
              <b-field>
                <b-button @click="toggleCreated()">Order by date
                </b-button>
              </b-field>

              <b-field>
                <b-taglist>
                  <!--              <b-tag-->
                  <!--                  type="is-info"-->
                  <!--                  v-if="courseSearchId"-->
                  <!--                  @close="courseSearchId = null"-->
                  <!--                  closable-->
                  <!--              >{{ $tc('Course', 1) }}-->
                  <!--              </b-tag>-->
                  <b-tag
                      type="is-info"
                      v-if="learnerSearchId"
                      @close="learnerSearchId = null;filter=null"
                      closable
                  >{{ $tc('Learner', 1) }}
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="guardianSearchId"
                      @close="guardianSearchId = null;filter=null"
                      closable
                  >{{ $tc('Guardian', 1) }}
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="ownerSearchId"
                      @close="ownerSearchId = null;filter=null"
                      closable
                  >Owner
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="phaseSearchId"
                      @close="phaseSearchId = null;filter=null"
                      closable
                  >Phase
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="stageSearchId"
                      @close="stageSearchId = null;filter=null"
                      closable
                  >Stage
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="order_by ==='priority'"
                      @close="togglePriority()"
                      closable
                  >Priority
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="showWonLost"
                      @close="showWonLost = false"
                      closable
                  >Won/Lost
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="for_programmes===1"
                      @close="for_programmes = false;programmeSearchId = false;"
                      closable
                  >Programme Applications
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="order_by==='created_at'"

                  >{{ order_direction === 'asc' ? 'Oldest First' : 'Newest First' }}
                  </b-tag>
                  <b-tag type="is-info" v-if="noOwner" @close="noOwner = false" closable>No Owner</b-tag>
                  <b-tag type="is-info" v-if="programmeSearchId" @close="programmeSearchId = null;filter=null"
                         closable>Programme
                  </b-tag>
                  <!--              <b-tag type="is-info" v-if="noCourse" @close="noCourse = false" closable>No Course</b-tag>-->
                  <b-tag type="is-info" v-if="statusFilter" @close="statusFilter = null" closable>Status</b-tag>
                  <b-tag type="is-info" v-if="yearFilter" @close="yearFilter = null" closable>Year</b-tag>
                  <b-tag
                      type="is-info"
                      v-if="semesterFilter"
                      @close="semesterFilter = null"
                      closable
                  >Semester
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="semesterFilter"
                      @close="semesterFilter = null"
                      closable
                  >Semester
                  </b-tag>
                  <b-tag
                      type="is-info"
                      v-if="rplCatFilter"
                      @close="rplCatFilter = null"
                      closable
                  >rpl/cat
                  </b-tag>
                </b-taglist>
              </b-field>
            </b-field>
            <b-field v-if="filter === 'programme'">
              <b-select required expanded placeholder="Programme"
                        v-model="programmeSearchId">
                <option
                    v-for="programme in programmes"
                    :key="programme.id"
                    :value="programme.id"
                >{{ programme.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="filter === 'learner'">
              <UsersFilter
                  :role="'learner'"
                  :type="'autocomplete'"
                  @selected="option => selectLearner(option)"
                  @cleared="learnerSearchId=null"
                  :stage_id="stageSearchId? stageSearchId:null"
                  :phase_id="phaseSearchId? phaseSearchId:null"
                  :campus_id="$store.state.campus.all_campuses?null:  $store.state.campus.selected_campus_id"></UsersFilter>
            </b-field>
            <b-field v-if="filter === 'guardian'">
              <UsersFilter
                  :role="'guardian'"
                  :type="'autocomplete'"
                  @selected="option => selectGuardian(option)"
                  @cleared="guardianSearchId=null"
                  :stage_id="stageSearchId? stageSearchId:null"
                  :phase_id="phaseSearchId? phaseSearchId:null"
                  :campus_id="$store.state.campus.all_campuses?null:  $store.state.campus.selected_campus_id"></UsersFilter>
            </b-field>
            <b-field v-if="filter === 'owner'">
              <UsersFilter
                  :without_roles="['guardian','learner']"
                  :type="'autocomplete'"
                  @selected="option => selectOwner(option)"
                  @cleared="ownerSearchId=null"
                  :stage_id="stageSearchId? stageSearchId:null"
                  :phase_id="phaseSearchId? phaseSearchId:null"
                  :campus_id="$store.state.campus.selected_campus_id"></UsersFilter>
            </b-field>
            <b-field v-if="filter === 'phase'">
              <PhasesFilter @selected="selectPhase"
                            :type="'autocomplete'"
                            :year="yearFilter"
                            :selected_phase_id="phaseSearchId"
                            @cleared="phaseSearchId = null"
                            :campus_id="$store.state.campus.selected_campus_id"></PhasesFilter>
            </b-field>
            <b-field v-if="filter === 'stage'">
              <StagesFilter @selected="selectStage"
                            :type="'autocomplete'"
                            :year="yearFilter"
                            :selected_stage_id="stageSearchId"
                            :phase_id="phaseSearchId"
                            @cleared="stageSearchId = null"
                            :campus_id="$store.state.campus.selected_campus_id"></StagesFilter>
            </b-field>
          </div>
          <div class="column is-2">
            <b-switch v-model="isTable" :true-value="true" :false-value="false">Table View</b-switch>
          </div>
        </div>
        <perfect-scrollbar-wrapper v-if="isTable && isLoaded">

        <b-table
            v-if="isTable && isLoaded"
            ref="table"
            :scrollable="false"
            class="margin-top is-clickable is-fullwidth"
            :data="tableHold"
            :striped="true"
            :hoverable="true"
            :bordered="false"
            narrowed
            backend-sorting
            @sort="onSort"
            @click="item => goToApplication(item.id)"
        >
          <template #empty>
            <div class="has-text-centered">No applications</div>
          </template>
          <b-table-column v-slot="props"
                          label="Won/Lost"
                          field="status_id"
          >{{ (props.row.victory_status_id == 3) ? 'Lost' : (props.row.victory_status_id == 2) ? 'Won' : 'Open' }}
          </b-table-column>
          <b-table-column label="First name" v-slot="props"
                          field="user.first_name">{{ props.row.user.first_name }}
          </b-table-column>
          <b-table-column v-slot="props"
                          label="Last name" field="user.last_name">{{ props.row.user.last_name }}
          </b-table-column>
          <b-table-column v-slot="props"

                            label="Owner"
                            field="owner.first_name"
            >{{ props.row.owner != null ? props.row.owner.first_name : '' }}
              {{ props.row.owner != null ? props.row.owner.last_name : '' }}
            </b-table-column>
            <b-table-column v-slot="props"

                          label="Creator"
                          field="creator.first_name"
          >{{ props.row.creator != null ? props.row.creator.first_name : '' }}
            {{ props.row.creator != null ? props.row.creator.last_name : '' }}
          </b-table-column>
          <b-table-column v-if="for_programmes!==1" v-slot="props"
                          label="Application Stage" field="application_stage_id">
            {{ props.row.stage !== null ? props.row.stage.name : 'Deleted Stage' }}
          </b-table-column>
          <b-table-column v-if="for_programmes===1" v-slot="props"
                          label="Programme" field="programme_id">
            {{ props.row.programme !== null ? props.row.programme.name : 'Deleted Programme' }}
          </b-table-column>
          <!--        <b-table-column-->
          <!--            :label="$tc('Course',1)"-->
          <!--            field="course.name"-->
          <!--        >{{ props.row.course != null ? props.row.course.name : '' }}-->
          <!--        </b-table-column>-->
          <b-table-column v-slot="props"
                          sortable
                          label="Priority" field="priority">{{ props.row.priority }}
          </b-table-column>
          <b-table-column v-slot="props"
                          label="Age" field="age_human_readable">{{ props.row.age_human_readable }}
          </b-table-column>
          <b-table-column v-slot="props"
                          label="Year and Month">{{ formatApplicationDate(props.row) }}
          </b-table-column>
          <b-table-column v-slot="props"

                            sortable
                            field="created_at"
                            label="Created at"
            >{{ props.row.created_at != null ? (new Date(props.row.created_at).toLocaleString()) : '' }}
            </b-table-column>
            <b-table-column v-slot="props"

                          sortable
                          field="updated_at"
                          label="Updated at"
          >{{ props.row.updated_at != null ? (new Date(props.row.updated_at).toLocaleString()) : '' }}
          </b-table-column>
        </b-table>
        </perfect-scrollbar-wrapper>
        <b-pagination
            v-if="isTable && isLoaded"
            class="margin-top"
            :total="meta.total"
            :current="page"
            :range-before="2"
            :range-after="2"
            :per-page="page_limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            v-on:change="setPage"
        ></b-pagination>
        <div @mouseenter="psUpdate" ref="scrollContainer" class="bar-container stage-columns is-fullwidth ">
          <div class="columns flippy" v-if="value && !isTable">
            <div class="column stage-column" v-for="stage in stages" :key="stage.id">
              <h3 class="has-text-centered" style="min-height: 3rem">{{ stage.name }}</h3>
              <draggable
                  :force-fallback="true"
                  :disabled="!$store.getters['entities/user-permissions/find']('edit applications')"
                  :class="{'column-target':dragging && draggingStage !== stage.id}"
                  @start="startDrag($event,stage.id)"
                  @end="endDrag($event,stage.id)"
                  v-model="stageSortedApplications[stage.id-1]"
                  :group="{name:stage.name, pull:true,put:true}"
                  @change="onChange($event, stage.id)"
                  class="column-view"
              >
                <router-link
                    :to="`/applications/edit/${application.id}`"
                    :class="{'application-dragged':dragging&& draggingApplication === application.id,'has-background-success':application.victory_status_id == 2,'has-background-danger':application.victory_status_id == 3,'priority-1':application.priority===1,'priority-2':application.priority===2,'priority-3':application.priority===3,'priority-4':application.priority===4,'priority-5':application.priority===5}"
                    class="box has-pointer has-text-grey-dark"
                    @click="goToApplication(application.id)"
                    v-for="application in stageSortedApplications[stage.id-1]"
                    :id="application.id"
                    :key="application.id"
                >
<span class="has-text-grey-dark">                  {{ application.user.first_name }} {{ application.user.last_name }}
</span>                  <!--              <br/>-->
                  <!--              <span v-if="application.course">{{ application.course.code }}</span>-->
                  <br/>
                  <span class="has-text-grey-dark" > <b-icon
                      :type="{'is-danger':applicationOwner(application.owner_id) === 'None'}"
                      :icon="$tc('icons.user')"
                      size="is-small"
                  ></b-icon>
                                   {{ applicationOwner(application.owner_id) }}</span>
                  <br/>
                  <p class="is-flex is-align-items-center has-text-grey-dark" v-if="application.total_tasks_count>0">
                    <b-icon :icon="$tc('icons.activities')" size="is-small"></b-icon>
                    <span
                        :class="{'has-text-success':application.completed_tasks_count >= application.total_tasks_count,'has-text-warning':application.completed_tasks_count<application.total_tasks_count}"
                        class="tasks-indicator"
                    >{{ application.completed_tasks_count }}/{{ application.total_tasks_count }}</span>
                  </p>
                  <p v-if="application.programme_id===null" class="has-text-grey-dark">
                    <b-icon :icon="$tc('icons.phases')" size="is-small"></b-icon>

                    {{ application.phase !== null ? application.phase.name : 'Deleted Phase' }}
                  </p>
                  <p v-if="application.programme_id===null" class="has-text-grey-dark">
                    <b-icon :icon="$tc('icons.stages')" size="is-small"></b-icon>

                    {{ application.stage !== null ? application.stage.name : 'Deleted Stage' }}
                  </p>
                  <p v-if="application.programme_id!==null" class="has-text-grey-dark">

                    {{ application.programme !== null ? application.programme.name : 'Deleted Programme' }}
                  </p>
                  <p class="has-text-grey-dark">
                    <b-icon :icon="$tc('icons.calendar')" size="is-small"></b-icon>

                    {{ formatApplicationDate(application) }}
                  </p>
                  <p class="has-text-grey-dark">
                    <b-icon :icon="$tc('icons.clock')" size="is-small"></b-icon>
                    {{ application.age_human_readable }}
                  </p>
                </router-link>
                <hr :class="{'mt-0':stageSortedApplications[stage.id-1].length<1}"/>
              </draggable>
            </div>
          </div>
        </div>

        <hr/>
        <infinite-loading
            :identifier="infiniteId"
            spinner="waveDots"
            v-if="value && !isTable"
            @infinite="infiniteHandler"
        ></infinite-loading>
      </div>
    </section>
  </section>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import ApplicationStage from "@/models/ApplicationStage";
import User from "@/models/User";

import Application from "@/models/Application";
import InfiniteLoading from "vue-infinite-loading";
import draggable from "vuedraggable";
import TitleBar from "@/components/layout/TitleBar";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import PhasesFilter from "@/components/panelled-dash/PhasesFilter";
import StagesFilter from "@/components/panelled-dash/StagesFilter";
import ApplicationStatus from "@/models/ApplicationStatus";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import ApplicationForm from "@/components/applications/ApplicationForm";
import {format} from 'date-fns'
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import PerfectScrollbar from "perfect-scrollbar";
import Programme from "@/models/Programme";

export default {
  name: "Applications",
  components: {
    TitleBar,
    InfiniteLoading,
    draggable,
    UsersFilter, PhasesFilter, StagesFilter, CampusHeader, PerfectScrollbarWrapper
  },
  props: {
    team_permission: {
      type: String,
      default() {
        return 'edit applications'
      }
    },
    board_title: {
      type: String, required: true
    },
    stage_order_offset: {
      type: Number,
      default() {
        return 0
      }
    }, stage_order_limit: {
      type: Number,
      default() {
        return 99
      }
    }, default_year: {
      Number,
      default() {
        // return new Date().getFullYear() + 1
        return null
      }
    }
  },
  data() {
    return {
      isLoaded: false,
      tableHold: [],
      infiniteId: new Date(),
      isTable: false,
      draggingStage: null,
      draggingApplication: null,
      dragging: false,
      showWonLost: false,
      for_programmes: false,
      filter: "",
      statusFilter: null,
      yearFilter: this.default_year,
      semesterFilter: null,
      rplCatFilter: 0,
      value: false,
      noOwner: false,
      // noCourse: false,
      learnerSearchTerm: "",
      ownerSearchTerm: "",
      // courseSearchTerm: "",
      learnerSearchId: null,
      // courseSearchId: null,
      ownerSearchId: null,
      guardianSearchId: null,
      programmeSearchId: null,
      phaseSearchId: null,
      stageSearchId: null,
      learnerSearchResponse: [],
      // courseSearchResponse: [],
      ownerSearchResponse: [],
      stageSortedApplications: [],
      order_by: 'created_at',
      order_direction: 'desc',
      card_page: 1,
      card_page_limit: 10,
      page: 1,
      page_limit: 50,
      meta: Object,
    };
  },

  mounted() {
    this.$store.dispatch("loader/show");
    ApplicationStage.FetchAll();
    ApplicationStatus.FetchAll();
    Programme.FetchAll({page: 1, limit: 99})

    if (!this.isTable) {
      this.psInit()
    }
    if (this.$store.state.campus.application_filter_status_id !== null) {
      this.statusFilter = this.$store.state.campus.application_filter_status_id
      this.$store.state.campus.application_filter_status_id = null
    }
    let applications_save = this.$cookies.get("applications_save");
    if (applications_save != null) {
      this.page = applications_save.page;
      this.learnerSearchId =
          applications_save.filters.hasOwnProperty("user_id") != null
              ? applications_save.filters.user_id
              : null;
      // this.courseSearchId =
      //     applications_save.filters.hasOwnProperty("course_id") != null
      //         ? applications_save.filters.course_id
      //         : null;
      this.showWonLost = applications_save.filters.hasOwnProperty("victory_status_id") != null;
      this.ownerSearchId =
          applications_save.filters.hasOwnProperty("owner_id") != null
              ? applications_save.filters.owner_id
              : null;
      this.for_programmes =
          applications_save.filters.hasOwnProperty("for_programmes") != null
              ? applications_save.filters.for_programmes
              : null;
      this.guardianSearchId =
          applications_save.filters.hasOwnProperty("guardian_id") != null
              ? applications_save.filters.guardian_id
              : null;
      this.programmeSearchId =
          applications_save.filters.hasOwnProperty("programme_id") != null
              ? applications_save.filters.programme_id
              : null;
      this.phaseSearchId =
          applications_save.filters.hasOwnProperty("phase_id") != null
              ? applications_save.filters.phase_id
              : null;
      this.stageSearchId =
          applications_save.filters.hasOwnProperty("stage_id") != null
              ? applications_save.filters.stage_id
              : null;
      this.statusFilter =
          applications_save.filters.hasOwnProperty("status_id") !=
          null
              ? applications_save.filters.status_id
              : null;
      this.yearFilter =
          applications_save.filters.hasOwnProperty("year") != null
              ? applications_save.filters.year
              : null;
      this.semesterFilter =
          applications_save.filters.hasOwnProperty("semester") != null
              ? applications_save.filters.semester
              : null;
      this.rplCatFilter =
          applications_save.filters.hasOwnProperty("rpl_cat_requested") != null
              ? applications_save.filters.rpl_cat_requested
              : null;
      if (applications_save.filters.hasOwnProperty("owner_id")) {
        if (applications_save.filters.owner_id == null) {
          this.noOwner = true;
        }
      }
      // if (applications_save.filters.hasOwnProperty("course_id")) {
      //   if (applications_save.filters.course_id == null) {
      //     this.noCourse = true;
      //   }
      // }

      this.isTable = applications_save.isTable;
      this.$cookies.remove("applications_save");

      if (!this.isTable) {
        this.psInit()
      }
    } else {
      this.getApplications(
          this.card_page,
          this.card_page_limit,
          this.filters,
          // !this.isTable
      ).then(() => {
        this.tableHold = this.applications;
        this.$store.dispatch("loader/hide");
      });
    }
  },
  watch: {
    isTable() {
      this.$store.dispatch("loader/show");

      Application.deleteAll();
      this.getApplications(
          this.page,
          this.page_limit,
          this.filters,
          // !this.isTable
      ).then(() => {
        this.isLoaded = true;
        this.tableHold = this.applications;
        this.$store.dispatch("loader/hide");
      });
    },
    filters() {
      if (this.isTable && this.isLoaded) {
        this.$store.dispatch("loader/show");
        this.page = 1;

        Application.deleteAll();
        this.getApplications(
            this.page,
            this.page_limit,
            this.filters,
            // !this.isTable
        ).then(() => {
          this.tableHold = this.applications;

          this.$store.dispatch("loader/hide");
        });
      }
      if (!this.isTable) {
        this.card_page = 1;
        this.infiniteId++;
        this.$store.dispatch("loader/show");
        Application.deleteAll();
        this.getApplications(
            this.card_page,
            this.card_page_limit,
            this.filters,
            // !this.isTable
        ).then(() => {
          this.$store.dispatch("loader/hide");
          this.$forceUpdate();
        });
      }
    },
  },
  methods: {
    psInit() {
      this.ps = new PerfectScrollbar(this.$refs.scrollContainer, {suppressScrollY: true})
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update()
      }
    },
    togglePriority() {
      if (this.order_by === 'priority') {
        this.order_by = 'created_at'
        this.order_direction = 'desc'
        // this.setPage(1)
      } else {
        this.order_by = 'priority'
        this.order_direction = 'desc'
        // this.setPage(1)
      }
    }, toggleCreated() {
      if (this.order_by === 'created_at') {
        this.order_direction = this.order_direction === 'desc' ? 'asc' : 'desc'
        // this.setPage(1)
      } else {
        this.order_by = 'created_at'
        this.order_direction = 'desc'
        // this.setPage(1)
      }
    },
    formatApplicationDate(application) {
      return format(new Date(application.year, application.month - 1), 'MMM yyy')
    },
    setWonLost() {
      this.showWonLost = true;
    }, setShowProgramme() {
      this.for_programmes = 1;
      this.phaseSearchId = null;
      this.stageSearchId = null;
    },
    startCreatingApplication() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: false,
          type: this.type,
          canCreate: !!this.$store.getters['entities/user-permissions/find']('create applications'),
          canCreateGuardian: !!this.$store.getters['entities/user-permissions/find']('create guardians'),
          canCreateLearner: !!this.$store.getters['entities/user-permissions/find']('create learners'),
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('create applications')
        },
        component: ApplicationForm,
        trapFocus: true,
        fullScreen: true,
        events: {
          "application-created": (id) => {
            this.goToApplication(id);
          },
        },
      });
    }
    , onSort(column, sort) {
      this.order_by = column;
      this.order_direction = sort;
      this.setPage(this.page);
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      Application.deleteAll();
      this.page = pageNumber;
      this.getApplications(
          this.page,
          this.page_limit,
          this.filters
          // !this.isTable
      ).then(() => {
        this.tableHold = this.applications;
        this.$store.dispatch("loader/hide");
      });
    },

    startDrag(evt, stageId) {
      this.dragging = true;
      this.draggingStage = stageId;
      /**
       * @param evt          The drag event.
       * @param evt.originalEvent   Contains the element of the dragged stage
       */
      this.draggingApplication = evt.originalEvent.target.id;
    },
    endDrag() {
      this.dragging = false;
      this.draggingStage = null;
      this.draggingApplication = null;
    },
    setNoOwner() {
      this.ownerSearchId = null;
      this.noOwner = true;
    },
    setNoCourse() {
      this.courseSearchId = null;
      this.noCourse = true;
    },
    twiddle() {
      this.setSortedApplications();
      this.$forceUpdate();
    },
    selectCourse(option) {
      this.courseSearchId = option.id;
      this.courseSearchResponse = [];
    },
    selectOwner(option) {
      this.noOwner = false;
      this.ownerSearchId = option.id;
      this.ownerSearchResponse = [];
    }, selectPhase(option) {
      this.phaseSearchId = option.id;
    }, selectStage(option) {
      this.stageSearchId = option.id;
    },
    selectLearner(option) {
      this.learnerSearchId = option.id;
    }, selectGuardian(option) {
      this.guardianSearchId = option.id;
    },
    getFilteredOwner(text) {
      this.ownerSearchResponse = User.query()
          .search(text, {
            caseSensitive: false,
            threshold: 0.3,
          })
          .get();
    },

    // getFilteredCourse(text) {
    //   this.loadingCourses = true;

    // Course.search(text, true).then((result) => {
    //   this.loadingCourses = false;
    //   if (result.entities) {
    //     this.courseSearchResponse = result.entities.courses;
    //   } else {
    //     this.courseSearchResponse = [];
    //   }
    // });
    // },
    applicationOwner(owner_id) {
      let owner = User.find(parseInt(owner_id));

      if (owner) {
        return `${owner.first_name} ${owner.last_name}`;
      } else {
        return `None`;
      }
    },
    goToApplication(id) {
      this.$cookies
          .set(
              "applications_save",
              {
                filters: this.filters,
                page: this.page,
                isTable: this.isTable,
                route: this.$router.currentRoute.path
              }
          );

      this.$router.push(`/applications/edit/${id}`);
    },

    setSortedApplications() {
      this.stages.forEach((element) => {

        this.stageSortedApplications[parseInt(element.id) - 1] = Application.query()
            .with("user")
            .with("programme")
            .with('phase').with('stage').with('application_stage')
            .where((application) => {
              for (let key in this.filters) {
                if (key === 'order_direction') {
                  return true
                }
                if (key === 'order_by') {
                  return true
                }
                if (application[key] !== this.filters[key]) {
                  return false
                }
              }
              return true;
            })
            .where("application_stage_id", parseInt(element.id))
            .orderBy(
                this.order_by != null ? this.order_by : "created_at",
                this.order_direction != null ? this.order_direction : "desc"
            )
            .get();
      });
      this.value = true;
    },
    onChange(evt, stage) {
      if (evt.hasOwnProperty("added")) {
        let application = evt.added.element;

        Application.update({
          where: application.id,
          data: {
            application_stage_id: stage,
          },
        }).then((result) => {
          Application.Update({
            id: result.id,
            application_stage_id: stage,
            user_id: result.user_id,
            status_id: result.status_id,
            campus_id: result.campus_id,
            priority: result.priority
          });
        });
      }
    },

    infiniteHandler($state) {
      return Application.FetchAll(
          {
            page: this.card_page,
            limit: this.card_page_limit,
          },
          {
            ...(this.isTable ? {} : {by_stages: 1}),

            ...this.filters,
            ...(this.order_by
                ? {
                  order_by: this.order_by,
                }
                : {order_by: "created_at"}),
            ...(this.order_direction
                ? {
                  order_direction: this.order_direction,
                }
                : {order_direction: "desc"}),
          },
          ['user', 'stage', 'phase']
      ).then(({response}) => {
        if (response.data.data.length > 0) {
          this.meta = response.data.meta;
          this.card_page++;
          this.twiddle();
          $state.loaded();
          return;
        }
        $state.complete();
      });
    },
    getApplications(page, limit, query) {
      return Application.FetchAll({page: page, limit: limit},
          {
            ...(this.isTable ? {} : {by_stages: 1}),
            ...query,
            ...(this.order_by
                ? {
                  order_by: this.order_by,
                }
                : {order_by: "created_at"}),
            ...(this.order_direction
                ? {
                  order_direction: this.order_direction,
                }
                : {order_direction: "desc"}),
          }, ['user', 'phase', 'stage'],
      ).then((result) => {
        if (result.response.data.meta) {
          this.meta = result.response.data.meta;
        }
        this.$cookies
            .set(
                "applications_save",
                {
                  filters: this.filters,
                  page: this.page,
                  isTable: this.isTable,
                  route: this.$router.currentRoute.path
                }
            );
        this.setSortedApplications();
      });
    },
  },

  computed: {
    programmes() {
      return Programme.query().orderBy('name').get()
    },
    filters() {
      return {
        ...(this.statusFilter
            ? {
              status_id: this.statusFilter,
            }
            : {}),
        ...(this.yearFilter
            ? {
              year: this.yearFilter,
            }
            : {}),
        ...(this.semesterFilter
            ? {
              semester: this.semesterFilter,
            }
            : {}),
        ...(this.rplCatFilter
            ? {
              rpl_cat_requested: this.rplCatFilter,
            }
            : {}),
        ...(this.learnerSearchId
            ? {
              user_id: this.learnerSearchId,
            }
            : {}),
        // ...(this.courseSearchId
        //     ? {
        //       course_id: this.courseSearchId,
        //     }
        //     : {}),
        ...(this.ownerSearchId
            ? {
              owner_id: this.ownerSearchId,
            }
            : {}),
        ...(this.guardianSearchId
            ? {
              guardian_id: this.guardianSearchId,
            }
            : {}), ...(this.programmeSearchId
            ? {
              programme_id: this.programmeSearchId,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {}), ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}), ...(this.for_programmes === 1
            ? {
              for_programmes: this.for_programmes,
            }
            : {}),
        ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.order_by
            ? {
              order_direction: this.order_direction,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {
              campus_id: this.$store.state.campus.selected_campus_id,
            }
            : {}),
        ...(!this.noOwner ? {} : {owner_id: null}),
        ...(this.showWonLost ? {} : {victory_status_id: 1}),

        // ...(!this.noCourse ? {} : {course_id: null}),
      };
    },
    statuses() {
      return ApplicationStatus.query().get()
    },
    applications() {
      return Application.query()
          .with("user")
          .with("programme")
          .with("owner")
          .with("creator")
          // .with("course")
          .with('phase')
          .with("stage")
          .with("application_stage")
          .where((application) => {
            for (let key in this.filters) {
              if (key === 'order_direction') {
                return true
              }
              if (key === 'order_by') {
                return true
              }
              if (application[key] !== this.filters[key]) {
                return false
              }
            }
            return true;
          })
          .orderBy(
              this.order_by != null ? this.order_by : "created_at",
              this.order_direction != null ? this.order_direction : "desc"
          )
          .get();
    },
    administrate_applications() {
      return this.$store.getters["entities/user-permissions/find"](
          "view applications"
      );
    },
    stages() {
      return ApplicationStage.query().orderBy("order", "asc").offset(this.stage_order_offset).limit(this.stage_order_limit).get();
    },
  },
};
</script>

<style lang="scss" scoped>
.column-view {
  .box {
    padding: 0.5rem;
  }

  height: 100%;
}

a:visited {
  color: unset !important;
}

.stage-columns {
  overflow-x: scroll;
  overflow-y: hidden;

  .column {
    &.stage-column {
      min-width: 175px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 95vw;
  overflow-y: hidden;
}

.flippy {
  max-width: 96vw;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

.application-dragged {
  border: 2px solid lightgreen;
}


.column-target {
  border: 2px solid lightblue;
  border-radius: 6px;
}
</style>
