import { render, staticRenderFns } from "./BoardIndex.vue?vue&type=template&id=582a8296&scoped=true&"
import script from "./BoardIndex.vue?vue&type=script&lang=js&"
export * from "./BoardIndex.vue?vue&type=script&lang=js&"
import style0 from "./BoardIndex.vue?vue&type=style&index=0&id=582a8296&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582a8296",
  null
  
)

export default component.exports